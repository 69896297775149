import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const YouTube = makeShortcode("YouTube");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "creating-channels-training-video"
    }}>{`Creating Channels Training Video`}</h1>
    <hr></hr>
    <p>{`In this quick tutorial, learn how to effortlessly create channels to organize and best showcase your app.`}</p>
    <YouTube mdxType="YouTube"><iframe src="//www.youtube.com/embed/vl68I71vwXo?si=zpntym84Bfug_SgM" frameBorder="0" allowFullScreen style={{
        "position": "absolute",
        "top": "0: left: 0",
        "width": "90%",
        "height": "90%"
      }}></iframe></YouTube>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      